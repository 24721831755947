// auth action types
export const authActionTypes = {
  SET_LOGGED_USER: "SET_LOGGED_USER",
  UPDATE_PROFILE_INFO: "UPDATE_PROFILE_INFO",
  UPDATE_AVATAR: "UPDATE_AVATAR",
};

// ui action types
export const uiActionTypes = {
  SET_LOADING: "SET_LOADING",
  CLEAR_LOADING: "CLEAR_LOADING",
  SET_ERRORS: "SET_ERRORS",
  CLEAR_ERRORS: "CLEAR_ERRORS",
  SET_PROGRESS: "SET_PROGRESS",
  CLEAR_PROGRESS: "CLEAR_PROGRESS",
};
// post action types
export const postActionTypes = {
  RESET_POSTS: "RESET_POSTS",
  FETCH_POSTS: "FETCH_POSTS",
  ADD_POST: "ADD_POST",
  DELETE_POST: "DELETE_POST",
  LOAD_CURRENT_POST: "LOAD_CURRENT_POST",
  UPDATE_POST: "UPDATE_POST",
  FETCH_SINGLE_POST: "FETCH_SINGLE_POST",
  ADD_COMMENT: "ADD_COMMENT",
  DELETE_COMMENT: "DELETE_COMMENT",
  TOGGLE_LIKE: "TOGGLE_LIKE",
};

// user action types

export const userActionTypes = {
  FETCH_SINGLE_USER: "FETCH_SINGLE_USER",
  FETCH_USERS: "FETCH_USERS",
  FOLLOW: "FOLLOW",
  UNFOLLOW: "UNFOLLOW",
};

// notification action types

export const notificationActionTypes = {
  FETCH_NOTIFICATIONS: "FETCH_NOTIFICATIONS",
  SEND_NOTIFICATION: "SEND_NOTIFICATION",
  MARK_NOTIFICATION_READ: "MARK_NOTIFICATION_READ",
};
